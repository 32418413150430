import React from "react";
import Layout from "../../components/Layout";
import { Link } from "gatsby";

const ProgramList = props => {
  const { data, programsData } = props.pageContext;
  const groupProgramIds = data.programs.map(groupProgram => {
    return groupProgram.program.uid;
  });
  const programs = programsData
    .map(({ node }) => {
      const { priority, title, summary } = node.data;

      return {
        priority,
        id: node.uid,
        title: title.text || "",
        summary: summary.text || ""
      };
    })
    .filter(program => {
      return groupProgramIds.includes(program.id);
    })
    .sort((a, b) => {
      // items without a priority set are placed at end of list
      if (!a.priority) return 1;
      if (!b.priority) return -1;
      if (a.priority > b.priority) return 1;
      if (a.priority < b.priority) return -1;
      return 0;
    });

  return (
    <Layout>
      <section>
        <h3>{data.name.text}</h3>
        <ul className="program-list">
          {programs.map(program => {
            return (
              <li key={program.id} className="program-list--card">
                <h4>{program.title}</h4>
                <p>{program.summary}</p>
                <Link className="button" to={`/programs/${program.id}`}>
                  More Information
                </Link>
              </li>
            );
          })}
        </ul>
      </section>
    </Layout>
  );
};

export default ProgramList;
